import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public isCollapsed = true;
  public scrWidth: any;
  public sidebarOpen = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  @ViewChild('myDiv') myDiv: any;

  constructor(private router: Router, private jwtHelper: JwtHelperService) {}

  ngOnInit(): void {
    this.scrWidth = window.innerWidth;
  }

  isLoggedIn() {
    if (localStorage.getItem('JWT')) {
      return true;
    }
    return false;
  }

  logout() {
    window.localStorage.removeItem('JWT');
    this.router.navigate(['/prijava']);
  }

  checkRoute() {
    return window.location.href.split('/').pop();
  }

  openNav() {
    this.sidebarOpen = true;
    document.getElementById('mySidebar')!.style.width = '250px';
    document.getElementById('main')!.style.marginLeft = '250px';
  }

  closeNav() {
    this.sidebarOpen = false;
    document.getElementById('mySidebar')!.style.width = '0';
    document.getElementById('main')!.style.marginLeft = '0';
  }

  menuNav(nav: string, isLogout: boolean) {
    if (isLogout) this.logout();
    this.router.navigate([nav]);
    this.closeNav();
  }
}
