import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UnAuthGuard } from './guards/unauth.guard';

const routes: Routes = [
  {
    path: 'prijava',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'registracija',
    pathMatch: 'full',
    loadChildren: () =>
      import('./components/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'registracija/:refferal',
    loadChildren: () =>
      import('./components/register/register.module').then(
        (m) => m.RegisterModule
      ),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'domov',
    redirectTo: window.localStorage.getItem('JWT') ? 'investments' : 'prijava',
    // loadChildren: () =>
    //   import('./components/homepage/homepage.module').then(
    //     (m) => m.HomepageModule
    //   ),
  },
  {
    path: 'aktiviraj',
    loadChildren: () =>
      import('./components/activation/activation.module').then(
        (m) => m.ActivationModule
      ),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'ponovno-aktiviraj',
    loadChildren: () =>
      import('./components/resend-activation/resend-activation.module').then(
        (m) => m.ResendActivationModule
      ),
    canActivate: [UnAuthGuard],
  },
  {
    path: 'profil',
    loadChildren: () =>
      import('./components/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'investments',
    loadChildren: () =>
      import('./components/investments/investments.module').then(
        (m) => m.InvestmentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./components/aboutus/aboutus.module').then(
        (m) => m.AboutusModule
      ),
  },
  {
    path: '**',
    redirectTo: window.localStorage.getItem('JWT') ? 'investments' : 'prijava',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
