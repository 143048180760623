<nav  *ngIf="scrWidth > 800" class="navbar navbar-expand-md" style="background: #141A1E !important">
  <a href="https://www.centerpodjetnikov.si/domov/investiranje/" class="navbar-brand" style="font-size:20px; color: #fff">
    <!-- Domov -->
    <img src="../../../assets/home.png" height="50">
    <!-- <i class="fa fa-home" style="margin-right:15px; font-size: 25px"></i> -->
  </a>
  <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
    <i class="fa fa-bars" style="color:#fff; font-size:28px;"></i>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
      <div class="navbar-nav">
          <!-- <a href="#" class="nav-item nav-link active" style="color: #fff">Home</a> -->

      </div>
      <div class="navbar-nav ml-auto" *ngIf="!this.isLoggedIn()">
          <a routerLink="/registracija" class="nav-item nav-link" style="font-size:20px"
          [ngStyle]="{'color': this.checkRoute() === 'registracija' ? '#FFD66E' : '#fff' }">
            <!-- <i class="fa fa-user" style="margin-right:25px; font-size:25px"
            [ngStyle]="{'color': this.checkRoute() === 'registracija' ? '#FFD66E' : '#fff' }"></i> -->
            Registracija
          </a>
          <a routerLink="/prijava" class="nav-item nav-link" style="font-size: 20px" 
          [ngStyle]="{'color': this.checkRoute() === 'prijava' ? '#FFD66E' : '#fff' }">
            <!-- <i class="fa fa-sign-in" style="margin-right:4px; font-size:25px"
            [ngStyle]="{'color': this.checkRoute() === 'prijava' ? '#FFD66E' : '#fff' }"></i> -->
            Prijava
          </a>
      </div>
      <div class="navbar-nav ml-auto" *ngIf="this.isLoggedIn()">
        <a routerLink="/investments" class="nav-item nav-link" style="font-size:20px"
        [ngStyle]="{'color': this.checkRoute() === 'investments' ? '#FFD66E' : '#fff' }">
          <!-- <i class="fa fa-bar-chart" style="margin-right:15px; font-size: 25px" 
          [ngStyle]="{'color': this.checkRoute() === 'investments' ? '#FFD66E' : '#fff' }">Dashboard</i> -->
          Dashboard
        </a>
        <a routerLink="/profil" class="nav-item nav-link" style="font-size: 20px" 
        [ngStyle]="{'color': this.checkRoute() === 'profil' ? '#FFD66E' : '#fff' }">
          <!-- <i class="fa fa-user" style="margin-right:15px; font-size: 25px"
          [ngStyle]="{'color': this.checkRoute() === 'profil' ? '#FFD66E' : '#fff' }"></i> -->
          Profil
        </a>
        <a (click)="this.logout()" class="nav-item nav-link" style="color: #fff; font-size:20px; cursor: pointer">
          <!-- <i class="fa fa-power-off" style="margin-right:15px; font-size: 25px"></i> -->
          Odjava
        </a>
    </div>
  </div>
</nav>

<!--mobile-->
<nav *ngIf="scrWidth <= 800" class="navbar" style="background: #141A1E !important">
    <a href="https://www.centerpodjetnikov.si/domov/investiranje/"
     class="nav-item nav-link" [ngStyle]="{'color': this.checkRoute() === 'domov' ? '#FFD66E' : '#fff' }">
      <i class="fa fa-home" style="margin-right:4px; font-size: 40px"></i></a>

      <!--book in middle-->
      <!-- <a *ngIf="this.isLoggedIn()" routerLink="/profil" class="nav-item nav-link" style="color: #fff">
        <i class="fa fa-book" style="margin-right:4px; font-size: 40px"></i></a> -->

        <a #paint *ngIf="this.isLoggedIn() && !this.sidebarOpen" style="color: #fff" (click)="openNav()" class="nav-item nav-link always-visible">
          <i class="fa fa-bars" style="margin-right:4px; font-size: 40px"></i></a>
          <a #paint *ngIf="this.isLoggedIn() && this.sidebarOpen" style="color: #FFD66E" (click)="closeNav()" class="nav-item nav-link always-visible">
            <i class="fa fa-bars" style="margin-right:4px; font-size: 40px"></i></a>

        <!--PRIJAVA / REGISTRACIJA / PROFIL-->
        <a *ngIf="!this.isLoggedIn()" routerLink="/prijava" class="nav-item nav-link" [ngStyle]="{'color': this.checkRoute() === 'prijava' || this.checkRoute() === 'registracija' ? '#FFD66E' : '#fff' }">
          <i class="fa fa-user" style="margin-right:4px; font-size: 40px"></i></a>
          <!--OLD USER-->
          <!-- <a *ngIf="this.isLoggedIn()" routerLink="/profil" class="nav-item nav-link" [ngStyle]="{'color': this.checkRoute() === 'profil' ? '#FFD66E' : '#fff' }">
            <i class="fa fa-user" style="margin-right:4px; font-size: 40px"></i></a> -->
</nav>

<div id="mySidebar" class="sidebar">
  <!-- <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a> -->
  <a (click)="this.menuNav('/profil', false)" [ngStyle]="{'color': this.checkRoute() === 'profil' ? '#FFD66E' : '#fff' }">Profil</a>
  <a (click)="this.menuNav('/investments', false)"  [ngStyle]="{'color': this.checkRoute() === 'investments' ? '#FFD66E' : '#fff' }">Investicije</a>
  <a (click)="this.menuNav('/prijava', true)" style="color: #fff">Odjava</a>
</div>